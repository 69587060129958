@import "sass-lib";

@media (min-width: 0px) and (max-width: 740px) {
	h3{
		font-size: 16px;
		&.braleway{font-size: 16px;}
	}
	h4{font-size: 16px;}
	.wrapper{
		padding: 0 20px;
		&.box-sideshare{padding: 0 20px;}
	}
	.paddheadertop{padding-top: 115px;}
	.paddheadermoretop{padding-top: 130px;}
	.paddsec{padding-bottom: 60px;}
	header{
		padding-top: 28px;
		.box-logo,.nav-right{width: 100%;}
		.box-logo{
			margin-bottom: 15px;
			.logo{
				margin-right: 20px;
				&:before{right:-10px;}
				img{width: 160px;}
			}
			.tx-logo{float: left;font-size: 14px;padding-top: 0;}
		}
		.nav-right{
			.box-lang{
				float: left;
				> span{padding-left: 0;}
			}
			.box-search{
				float: right;margin-left: 0;
				&.newslist{display: block;}
			}
		}

		&.float{height: 90px;}
	}
	.box-search{
		> span{display: none;}
		.drop-search{
			display: block;position: relative;top: auto;right: auto;background: transparent;
			input[type=text]{background: transparent;text-align: right;height: 20px;width: 170px;}
			input[type=submit]{height: 20px;width: 15px;background-size: 100%;top: 0;}
		}
	}

	.box-slider-home{
		padding: 125px 0 120px;
		.sliderhome{margin-left: 0;}
		.left{
			top: auto;bottom: 75px;height: auto;padding: 0;width: auto;right: auto;left: 20px;
			h1{display: none;}
			a{font-size: 13px;}
			.in-text-slidehome{width: auto;}
		}
		.arrow-slidehome{right: 20px;bottom: 70px;}
	}
	.content-home{padding: 30px 0;}
	.sliderhome{
		.list{
			.btn-play{width: 50px;height: 50px;bottom: 40px;}
			.desc{padding: 0 15px 15px;}
		}
	}
	.box-news{
		background: transparent;@include boxShadow(none);
		> .left,> .right{width: 100%;}
		> .left{
			position: relative;bottom: auto;left: auto;right: auto;top: auto;
			h2{font-size: 18px;margin-bottom: 10px;}
			a{font-size: 13px;}
			figure{
				img{position: relative;bottom: auto;left: auto;right: auto;top: auto;}
			}
			.text{padding: 0 20px 20px;}
		}
		> .right{
			padding: 20px 0 0;
		}
	}
	.side-th{
		.text{
			padding-top: 5px;
			p{line-height: 18px;height: 36px;}
		}
		&.side-video{
			figure{
				&:after{width:30px;height: 30px;}
			}
		}
	}
	.potrait-th{
		&.side-video{
			figure{
				&:after{width:30px;height: 30px;}
			}
		}
	}
	.in-box-news{
		.potrait-th{
			.text{
				padding-top: 5px;
				p{line-height: 18px;height: 36px;}
			}
			&:nth-child(n+3){display: block;}
		}
	}
	.box-tag{
		padding: 20px 15px;
		h3{line-height: 24px;}
	}
	.tags{
		a{font-size: 12px;padding: 0 15px;height: 25px;line-height: 25px;margin:0 2px 7px;}
	}
	.box-popular-news{
		padding: 20px;
		> .left,> .right{width: 100%;}
		> .left{
			position: relative;top: auto;left: auto;bottom: auto;right: auto;
			figure{
				display: none;
				img{position: relative;top: auto;left: auto;bottom: auto;right: auto;}
			}
			.text{position: relative;top: auto;left: auto;bottom: auto;right: auto;color: #333;text-align: left;padding: 0;}
		}
		> .right{padding: 20px 0 0;}
	}
	.slidepopular{
		.list{
			margin:0;
			.btn-play{width: 50px;height: 50px;}
			.desc{
				padding: 0 20px 15px;
				p{font-size: 13px;line-height: 18px;height: 36px;}
				.label{margin-bottom: 5px;}
			}
		}
		.slick-arrow{width: 30px;height: 30px;}
	}
	.highlight{padding-top:40px;padding-bottom: 40px;}
	.sliderwp{
		height: 185px;
		.inner-sliderwp{
			.list{
				padding: 0 5px;margin-top: 10px;
				figure{width: 260px;}
				.desc{
					left: 5px;right: 5px;padding: 0 15px 15px;
					h4{font-size: 13px;line-height: 18px;height: 36px;}
				}
			}
		}
	}
	.top-highlight{
		margin-bottom: 20px;
		.left{
			width: 60%;
			h3{margin-bottom: 10px;display: block;margin-right: 0;}
		}
	}
	.subnav{
		a{font-size: 13px;padding: 0 10px;}
	}
	.box-tags{
		.btn-tags{font-size: 13px;}
		.drop-tags{
			.in-drop-tags{
        max-height: 500px;
        padding:20px 15px;
      }
			.tags-overlay{
				.close-tags{
					width: 30px;height: 30px;
					span{width: 15px;height: 2px;margin-left: -7px;}
				}
			}
		}
		.tags{
			a{margin: 0 3px 10px;max-width: 100px;overflow: hidden;word-wrap: break-word;}
		}
	}
	.select-custom{
		.btn-select{font-size: 13px;}
		.drop-select{font-size: 13px;}
	}
	.box-subnav{
		padding-bottom: 15px;
		.box-search-nav{display: none;}
		.nav-left,.nav-right{width: 100%;}
		.nav-left{
			padding-top: 0;border-bottom: 1px solid #eeeeee;margin-bottom: 5px;
			.subnav{margin-bottom: 15px;}
			&.noborderres{border-bottom: none;}
		}
		.nav-right{
			padding-top: 0;padding-bottom: 0;
			.linev{display: none;}
		}
		.box-tags,.select-custom{width: 100%;}
		.select-custom{
			.btn-select{padding-left: 0;}
		}
		.box-tags{
			margin-bottom: 10px;
			.btn-tags{padding-top: 5px;padding-bottom: 10px;border-bottom: 1px solid #eeeeee;}
		}
		.linev{
			&.hideres{display: none;}
		}
	}
	.box-thumb{
		.ls-thumb{
			width: 100%;margin-bottom: 20px;
			p{font-size: 13px;line-height: 18px;max-height: 36px;}
			figure{
				.label{left: 15px;bottom: 15px;}
			}
			.desc{padding: 15px;}

			&.ls-video{
				figure{
					&:after{width:50px;height: 50px;}
				}
			}
		}
	}
	.pagination{
		a{
			font-size: 13px;margin: 0 3px;
			&.prev,&.next{display: none;}
		}
		.dots{margin: 0 3px;}
	}
	.contentwp{
		h3{font-size: 20px;line-height: 24px;}
		h4{font-size: 18px;line-height: 24px;}
		h6{font-size: 18px;line-height: 24px;margin-bottom: 10px;}
		p{font-size: 14px;line-height: 24px;}
		blockquote{
			font-size: 20px;line-height: 24px;padding: 50px 20px;
			&:before{left:20px;top: 15px;width: 20px;}
			&:after{right:20px;bottom: 20px;width: 20px;}
		}
		ul,ol{
			li{font-size: 14px;line-height: 24px;padding-left: 20px;}
		}
		ul{
			li{
				&:before{left:0px;}
			}
		}
		ol{
			li{
				&:before{width:5px;height: 5px;top: 9px;left: 2px;}
			}
		}
		table{
			thead{display: none;}
			th,td{
				display: block;
				&:before{content:attr(data-title);display: block;margin-bottom: 3px;font-weight: 700;font-size: 13px;}
			}
		}
	}
	.slider-lg{
		.list{
			.title-slider{padding: 0 20px 15px;}
		}
	}
	.box-sideshare{
		.share-side{display: none;}
	}
	.slider-thumb{
		padding: 20px 30px;
		.list{margin: 0 3px;}
		.slick-arrow{
			&.slick-prev{left: 10px;}
			&.slick-next{right: 10px;}
		}
	}
	.bg-grey-tag{
		padding: 10px 20px 10px;
		label{line-height: 24px;display: block;margin-bottom: 10px;}
		.tags{
			&.withmarg{
				a{
					&:first-child{margin-left:0;}
					&:nth-child(n+3){display: none;}
				}
			}
		}
	}
	.tags{
		&.withmarg{
			a{margin: 0 3px 10px;}
		}
	}
	.other-news{
		padding: 20px;
		h5{font-size: 16px;}
	}
	.thumb-other-news{
		.side-th{width: 100%;}
	}
	.box-rounded{margin-bottom: 20px;padding: 20px;}
	.sidearticle{
		aside{
			.box-rounded{width: 100%;}
		}
	}
	.tx-list{
		margin-bottom: 15px;
		.tblue{font-size: 13px;margin-bottom: 5px;}
	}
	.banner-detail{
		margin-bottom: 20px;
		h3{font-size: 18px;line-height: 24px;}
		figure{
			margin-bottom: 20px;
			&:before{display:none;}
		}
		.text{position: relative;top: auto;left: auto;right: auto;bottom: auto;color: #333;background: transparent;}
	}
	.error-page{
		min-height: 600px;
		.in-error-page{
			margin-top: -30px;
			img{width: 270px;}
			h4{font-size: 16px;line-height: 24px;}
			a{font-size: 16px;}
		}
	}
	.thumb-img{
		.ls-thumb-img{
			margin-bottom: 5px;width: 100%;
			span{font-size: 14px;line-height: 20px;padding: 0 20px;max-height: 40px;bottom: 20px;}
		}
	}

	footer{
		.foot-left{font-size: 13px;line-height: 18px;text-align: center;width: 100%;margin-bottom: 15px;}
		.foot-right{width: 100%;text-align: center;}

		&.footmin{margin-top: -145px;}
	}
}

@media (max-width: 767px) and (max-height: 640px) {
  .box-tags .drop-tags .in-drop-tags {
    max-height: 280px;
  }
}